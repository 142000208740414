<template>
    <div class="instructor-details-desc" v-if="!isLoading">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end pb-10">
            <router-link :to="{ name: 'TrainerAddCourses'}" class="btn default-btn">Make A New Course</router-link>
        </div>
        <div v-if="courses.length">
            <ul class="list-group" v-for="course in courses" :key="course.id">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">{{ course.title }}</div>
                    </div>
                    <span class="badge text-default rounded-pill">
                        <router-link :to="{ name: 'TrainerCoursesAssigment', params: {id: course.id}}" class="btn text-default">
                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path></svg>
                        </router-link>
                        <router-link :to="{ name: 'CourseDashboard', params: {id: course.id}}" class="btn text-default">
                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                        </router-link>
                        <button class="btn text-default" @click="deleteCourse(course.id)">
                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>  
                        </button>
                    </span>
                </li>
            </ul>
        </div>
        <div class="text-center" v-else>
            <h3>No Course Has Been Added</h3>
            <router-link :to="{ name: 'TrainerAddCourses'}" class="btn default-btn">Make A New Course</router-link>
        </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Loading from '@/components/Loading.vue'
export default {
    components: { Loading, },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            courses: {},
            errors: {},        
        }
    },
    methods:{
        getCourses(){
            axios.post('https://apitraining.vipawaworks.com/api/course/get_course_by_request_company').then(response => {
                this.courses = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        deleteCourse(id) {
                Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ff1949',
                cancelButtonColor: '#ffc107',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('https://apitraining.vipawaworks.com/api/course/delete/'+ id)
                    .then(response => {
                        response.data
                        this.courses = this.courses.filter(course => {
                        return course.id != id
                        })
                    }).catch((errors) => {
                        this.errors = errors.response.data.errors
                    })
                    Swal.fire(
                    'Deleted!',
                    'Specialist has been Deleted.',
                    'success'
                    )
                }
                })
            },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getCourses() 
    }
}
</script>

<style>

</style>